import React from 'react';

import { useSpring, animated } from 'react-spring';
import { useShopifyService } from '../../shopifyService';
import { formatCurrency } from '../../helpers';
import { LineItem } from './LineItem';

import { ReactComponent as Close } from '../../svg/icon/icon-x.svg';
import { useEscKeyListener } from '../../hooks';

export const Cart: React.FC = () => {
  const { checkout, dispatch, isCartOpen } = useShopifyService();

  const handleCloseCart = () => dispatch({ type: 'closeCart' });

  const props = useSpring({
    transform: isCartOpen ? 'translateX(0px)' : 'translateX(400px)',
  });

  useEscKeyListener(handleCloseCart, isCartOpen);

  if (!checkout) {
    return null;
  }

  const isCartEmpty = !checkout.lineItems?.length ?? 0;

  const handleOpenCheckout = () => {
    if (checkout && !isCartEmpty) {
      window.open(checkout.webUrl);
    }
  };

  return (
    <animated.div
      style={{
        width: 400,
        ...props,
      }}
      className="fixed top-0 right-0 flex flex-col ml-auto h-screen shadow-lg border-l z-30 bg-white"
    >
      <section className="section-sm flex flex-col flex-1">
        <header className="mb-8">
          <button
            type="button"
            className="bg-gray-800 bg-white absolute mt-2 mr-3 top-0 right-0 flex shadow-lg hover:shadow-xl items-center px-3 h-10 hover-bg-gray-700"
            onClick={handleCloseCart}
          >
            <Close className="text-2xl font-bold text-white" />
          </button>
        </header>
        <div className="flex flex-col flex-1 overflow-scroll ">
          {checkout.lineItems?.map((node) => {
            return <LineItem key={node.id} node={node} />;
          })}
          {isCartEmpty && (
            <p className="h2 mt-8">
              Cart is empty
              <span className="ml-1" role="img" aria-label="Sad face">
                😐
              </span>
            </p>
          )}
        </div>
      </section>
      <div className="section-sm flex flex-col mt-auto bg-gray-200">
        <div>
          <p className="text-sm">Total:</p>
          <p className="h3">
            {formatCurrency({
              price: checkout.subtotalPrice,
            })}
          </p>
        </div>
        <button
          disabled={isCartEmpty}
          type="button"
          className="btn bg-orange-500 text-white text-lg"
          onClick={handleOpenCheckout}
        >
          Checkout
        </button>
      </div>
    </animated.div>
  );
};
