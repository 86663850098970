import React, { useState, useLayoutEffect } from 'react';
import { useSpring, animated } from 'react-spring';

import Footer from '../Footer';
import Navbar from '../Navbar';
import { Cart } from '../Cart';
import { Meta } from '../Meta';
import { useShopifyService } from '../../shopifyService';
import { Overlay } from './Overlay';
import { useEscKeyListener } from '../../hooks';

export const Layout: React.FC = ({ children }) => {
  const { isCartOpen, dispatch } = useShopifyService();
  const handleCloseCart = () => dispatch({ type: 'closeCart' });

  // TODO Layout/context.ts
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  useEscKeyListener(() => setIsNavbarOpen(false), isNavbarOpen);

  return (
    <>
      <Meta />
      <main className="mt-12">
        <Overlay visible={isCartOpen} onClick={handleCloseCart} />
        <Navbar setMenuVisible={setIsNavbarOpen} menuVisible={isNavbarOpen} />
        {children}
        <Footer />
      </main>
      <Cart />
    </>
  );
};
