import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

import { navLinks } from './Navbar';

export const InlineMenu: React.FC = () => {
  return (
    <nav className={clsx('flex')}>
      {navLinks.map(({ path, text }) => {
        return (
          <Link
            className={clsx(
              'font-medium tracking-widest block',
              'text-gray-700 text-xs uppercase px-4 flex items-center hover:bg-white'
            )}
            to={path}
            key={path}
          >
            {text}
          </Link>
        );
      })}
    </nav>
  );
};
