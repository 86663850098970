import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

import logo from '../../svg/img/logo.svg';
import { useShopifyService } from '../../shopifyService';
import { ReactComponent as CartIcon } from '../../svg/icon/icon-cart.svg';
import { ReactComponent as MenuIcon } from '../../svg/icon/icon-menu.svg';
import { useBreakpoint } from '../../hooks';
import { OverlayMenu } from './OverlayMenu';
import { InlineMenu } from './InlineMenu';

export type NavLink = { path: string; text: string };

export const navLinks: NavLink[] = [
  { path: '/about', text: 'About' },
  { path: '/blog', text: 'Blog' },
  { path: '/products', text: 'Products' },
  { path: '/contact', text: 'Contact' },
  // { path: '/contact/examples', text: 'Form Examples' },
];

export interface NavbarProps {
  menuVisible?: boolean;
  setMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Navbar: React.FC<NavbarProps> = ({ menuVisible = false, setMenuVisible }) => {
  const { checkout, dispatch } = useShopifyService();

  const handleToggleCart = () => {
    dispatch({ type: 'toggleCart' });
  };

  const breakpoint = 'md';
  const isDesktop = useBreakpoint(breakpoint);

  const cartQty =
    (checkout &&
      checkout.lineItems.reduce((total, lineItem) => {
        return total + lineItem.quantity;
      }, 0)) ??
    0;

  return (
    <>
      <header className="flex items-stretch justify-between flex-wrap h-12 fixed top-0 w-full z-20 bg-transparent-white transition ease-in-out duration-700">
        <Link className="mr-auto inline-flex items-center px-4 hover:bg-white" to="/">
          <img src={logo} alt="Kaldi" className="w-8" />
        </Link>
        {isDesktop && <InlineMenu />}
        <button
          type="button"
          className="flex items-center px-4 hover:bg-white relative"
          onClick={handleToggleCart}
        >
          <CartIcon className="text-lg mr-2" />
          <span className="absolute top-0 right-0 mt-2 mr-3 shadow w-4 h-4 text-xs font-medium bg-orange-600 text-gray-200 rounded-full text-center align-middle">
            {cartQty}
          </span>
        </button>

        <button
          type="button"
          className="flex items-center px-4 hover:bg-white"
          onClick={() => setMenuVisible(true)}
        >
          <MenuIcon className="text-lg mr-2" />
        </button>
      </header>
      <OverlayMenu menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
    </>
  );
};
