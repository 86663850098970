import React from 'react';
import { Link } from 'gatsby';
import { animated, useTransition } from 'react-spring';
import clsx from 'clsx';

import { ReactComponent as CloseIcon } from '../../svg/icon/icon-x.svg';

import { navLinks } from './Navbar';
import { useBreakpoint } from '../../hooks';

export interface OverlayMenuProps {
  menuVisible: boolean;
  setMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OverlayMenu: React.FC<OverlayMenuProps> = ({ menuVisible, setMenuVisible }) => {
  const transition = useTransition(menuVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const breakpointMet = useBreakpoint('sm');

  return (
    <>
      {transition.map(({ item, props, key }) => {
        return (
          item && (
            <animated.div
              key={key}
              className={clsx(
                'fixed inset-0 bg-transparent-white w-screen h-screen section z-30 flex flex-col section h-full'
              )}
              style={props}
            >
              <button
                type="button"
                className="bg-gray-800 bg-white absolute mt-2 mr-3 top-0 right-0 flex shadow-lg hover:shadow-xl items-center px-3 h-10 hover-bg-gray-700"
                onClick={() => setMenuVisible(false)}
              >
                <CloseIcon className="text-2xl font-bold text-white" />
              </button>
              <nav className="flex flex-col justify-center section-y">
                {navLinks.map(({ path, text }) => {
                  return (
                    <Link
                      className={clsx('px:12 py:16 mb-8 inline-flex h1', breakpointMet && 'title')}
                      to={path}
                      key={path}
                    >
                      {text}
                    </Link>
                  );
                })}
              </nav>
            </animated.div>
          )
        );
      })}
    </>
  );
};
