import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import clsx from 'clsx';
import logo from '../svg/img/logo.svg';
import { ReactComponent as Facebook } from '../svg/icon/facebook.svg';
import { ReactComponent as Instagram } from '../svg/icon/instagram.svg';
import { ReactComponent as Twitter } from '../svg/icon/twitter.svg';
import { ReactComponent as Vimeo } from '../svg/icon/vimeo.svg';
import { FooterQuery } from '../../graphql-types';

export const query = graphql`
  query Footer {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const linkClassNames = clsx(
  'text-xs mb-2 block py-1 sm:px-2 text-gray-700 font-medium sm:mb-0 md:text-sm uppercase tracking-widest'
);

const Footer: React.FC = () => {
  const { site } = useStaticQuery<FooterQuery>(query);

  return (
    <footer>
      <section className="flex items-start sm:items-center justify-between section bg-gray-100 md:h-56">
        <nav className="flex flex-col sm:flex-1 sm:flex-row sm:justify-between sm:items-center sm:mt-auto">
          <ul className="flex ml-auto w-full flex-col sm:flex-row sm:text-left justify-center sm:justify-start">
            <li className="">
              <Link className={linkClassNames} to="/">
                Home
              </Link>
            </li>
            <li className="">
              <Link className={linkClassNames} to="/about">
                About
              </Link>
            </li>
            <li className="">
              <Link className={linkClassNames} to="/blog">
                Blog
              </Link>
            </li>
            <li className="">
              <Link className={linkClassNames} to="/products">
                Products
              </Link>
            </li>
            <li>
              <Link className={linkClassNames} to="/contact">
                Contact
              </Link>
            </li>
          </ul>
          <div className="flex ml-auto w-full sm:w-auto justify-center text-right">
            <a className="text-sm px-2 mr-1" title="facebook" href="https://facebook.com">
              <Facebook />
            </a>
            <a className="text-sm px-2 mr-1" title="twitter" href="https://twitter.com">
              <Twitter />
            </a>
            <a className="text-sm px-2 mr-1" title="instagram" href="https://instagram.com">
              <Instagram />
            </a>
            <a className="text-sm px-2 mr-1 sm:mr-0" title="vimeo" href="https://vimeo.com">
              <Vimeo />
            </a>
          </div>
        </nav>
      </section>
      <footer className="section-x section-y-xs py-2 bg-gray-200 flex items-center">
        <p className="text-xs">
          &copy; {site.siteMetadata.title} 2020. Website by{' '}
          <a href="https://alexboffey.co.uk">Alex Boffey</a>.
        </p>
      </footer>
    </footer>
  );
};

export default Footer;
