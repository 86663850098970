import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

import { useShopifyService, LineItemWithVariant } from '../../shopifyService';
import { formatCurrency } from '../../helpers';
import { ReactComponent as Plus } from '../../svg/icon/icon-plus.svg';
import { ReactComponent as Minus } from '../../svg/icon/icon-minus.svg';
import { ReactComponent as Close } from '../../svg/icon/icon-x.svg';

export interface LineItemProps {
  node: LineItemWithVariant;
}

export const LineItem: React.FC<LineItemProps> = ({ node }) => {
  const { loading, removeLineItemInCart, updateLineItemInCart } = useShopifyService();
  const [inputQuantity, setInputQuantity] = useState<string>(node.quantity.toString());
  const quantityRef = useRef(node.quantity);

  // Keep component control state up to date with store
  useEffect(() => {
    if (node.quantity !== quantityRef.current) {
      quantityRef.current = node.quantity;
      setInputQuantity(node.quantity.toString());
    }
  }, [node, inputQuantity, loading]);

  const handleRemoveLineItemFromCart = () => removeLineItemInCart({ lineItemId: node.id });

  const debouncedUpdateLineItemInCart = debounce(updateLineItemInCart, 500);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = e.target.value;
    setInputQuantity(quantity);
    if (!quantity || parseInt(quantity, 10) < 0) {
      return;
    }
    debouncedUpdateLineItemInCart({ lineItemId: node.id, quantity });
  };

  const handleQuantityIncrement = () => {
    const nextQuantity = (parseInt(inputQuantity, 10) + 1).toString();
    setInputQuantity(nextQuantity);
    debouncedUpdateLineItemInCart({ lineItemId: node.id, quantity: nextQuantity });
  };

  const handleQuantityDecrement = () => {
    const nextQuantity = (parseInt(inputQuantity, 10) - 1).toString();
    setInputQuantity(nextQuantity);
    debouncedUpdateLineItemInCart({ lineItemId: node.id, quantity: nextQuantity });
  };

  const handleOnBlur = () => {
    if (!inputQuantity || (parseInt(inputQuantity, 10) < 0 && node.quantity)) {
      setInputQuantity(node.quantity.toString());
    }
  };

  const shouldRenderVariantTitle = node.variant.title && node.variant.title !== 'Default Title';

  return (
    <div className="flex items-center mb-8">
      <section className="flex">
        <img
          className="mr-4"
          style={{ height: 112, width: 'auto' }}
          src={node.variant.image.src}
          alt=""
        />
        <div className="pt-4">
          <p className="h6">{node.title}</p>
          <p className="text-sm gray-600 mb-2">
            {formatCurrency({
              price: node.variant.price,
            })}
            {shouldRenderVariantTitle && <span> - {node.variant.title}</span>}
          </p>
          <div className="flex justify-between items-center text-sm w-24">
            <button type="button" className="btn mr-2 p-1" onClick={handleQuantityDecrement}>
              <Minus />
            </button>
            <input
              className="form-input w-8 text-center text-sm p-1"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              value={inputQuantity}
            />
            <button type="button" className="btn p-1 ml-2" onClick={handleQuantityIncrement}>
              <Plus />
            </button>
          </div>
        </div>
      </section>
      <div className="ml-auto flex items-center">
        <button className="btn ml-2 p-2" type="button" onClick={handleRemoveLineItemFromCart}>
          <Close />
        </button>
      </div>
    </div>
  );
};
