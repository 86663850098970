import { useLayoutEffect, useState, useCallback } from 'react';

export const useWindowSize = () => {
  const isClient = typeof window === 'object';
  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  };
  const [windowSize, setWindowSize] = useState(getSize);

  useLayoutEffect(() => {
    if (!isClient) {
      return;
    }

    const handleResize = () => setWindowSize(getSize());

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export type Breakpoint = 'sm' | 'md' | 'lg' | 'xl';

export const breakpointMap: Record<Breakpoint, number> = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

export const useBreakpoint = (breakpoint: Breakpoint): boolean => {
  const { width } = useWindowSize();
  return width >= breakpointMap[breakpoint];
};

export const useEscKeyListener = (fn: () => void, condition = true) => {
  const closeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        fn();
      }
    },
    [fn]
  );

  useLayoutEffect(() => {
    if (condition) {
      window.addEventListener('keyup', closeListener);
    }
    return () => window.removeEventListener('keyup', closeListener);
  }, [condition, closeListener]);
};
