import React from 'react';
import { animated, useTransition } from 'react-spring';
import clsx from 'clsx';

interface OverlayProps {
  visible: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const Overlay: React.FC<OverlayProps> = ({ visible, onClick }) => {
  const transition = useTransition(visible, null, {
    from: { opacity: 0 },
    enter: { opacity: 0.3 },
    leave: { opacity: 0 },
  });
  return (
    <>
      {transition.map(
        ({ item, props, key }) =>
          item && (
            <animated.div
              key={key}
              role="dialog"
              onClick={onClick}
              className={clsx('fixed w-full h-full top-0 left-0 inset z-30 bg-black')}
              style={props}
            />
          )
      )}
    </>
  );
};
